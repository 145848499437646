<template>
  <AppBar class="jurisdiction">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/role' }" replace>角色列表</el-breadcrumb-item>
      <el-breadcrumb-item>权限修改</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs type="border-card" class="jurisdiction-box">
      <el-tab-pane label="前台权限">
        <el-form>
          <el-form-item
            v-for="item in allActions[0]"
            :key="item.uuid"
            :label="item.name + '：'"
            label-width="200px"
          >
            <el-checkbox-group v-model="rolePermissions">
              <el-checkbox
                :label="permission.id"
                :name="permission.uuid"
                v-for="permission in item.permissions"
                :key="permission.uuid"
              >
              {{permission.caption}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="后台权限">
        <el-form>
          <el-form-item
            v-for="item in allActions[1]"
            :key="item.uuid"
            :label="item.name + '：'"
            label-width="200px"
          >
            <el-checkbox-group v-model="rolePermissions">
              <el-checkbox
                :label="permission.id"
                :name="permission.uuid"
                v-for="permission in item.permissions"
                :key="permission.uuid"
              >
              {{permission.caption}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-row class="footer">
      <el-button type="primary" round @click="preservation">保存</el-button>
      <el-button type="danger" round plain @click="cancel">取消</el-button>
    </el-row>
  </AppBar>
</template>
<script>
import AppBar from "@/components/AppBar";
import Api from "@/api/index";
export default {
  name: "jurisdiction",
  data() {
    return {
      uuid: "",
      loginMsg: sessionStorage.getItem("loginMsg"), //当前登录用户的信息
      allActions: [[], []],
      rolePermissions: [],
    }
  },
  methods: {
    // 保存
    preservation() {
      console.log(this.rolePermissions);
      let rolePermissions = this.rolePermissions.filter((item, index) => {
        return this.rolePermissions.indexOf(item) === index;
      })
      let params = {
        role: this.uuid,
        permissions: rolePermissions
      }
      Api.saveAuthorization(params)
        .then(res => {
          console.log(res);
          this.$router.push({
            path: "/role"
          });
          this.$message.success("保存成功")
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    // 判断是否拥有此权限
    // judgeContain(id) {
    //   let flag = false;
    //   this.rolePermissions.forEach(item => {
    //     if(item.id === id) flag = true
    //   })
    //   return flag;
    // },
    // 取消修改
    cancel() {
      this.$confirm("是否放弃修改离开页面？", {
        distinguishCancelAndClose: true,
        confirmButtonText: "继续修改",
        cancelButtonText: "放弃修改"
      })
        .then(() => {})
        .catch(action => {
          if (action == "cancel") {
            this.$router.push({
              path: "/role"
            });
          }
        });
    },
    // 获取角色所有的权限
    getRolePermissions() {
      Api.getRolePermissions(this.uuid).then(res => {
        if(res.data) {
          this.rolePermissions = [];
          res.data.data.forEach(item => {
            this.rolePermissions.push(item.id)
          })
        }
      });
    },
    // 获取所有的权限
    getAllActions() {
      Api.getAllActions()
        .then(res => {
          if (res.data) {
            res.data.data.forEach(item => {
              if (item.type === "webModule") {
                this.allActions[1].push(item);
              } else {
                this.allActions[0].push(item);
              }
            });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  },
  mounted() {
    // 获取到需要更改的角色id
    this.uuid = this.$route.query.uuid;
    this.getRolePermissions();
    this.getAllActions();
  },
  components: {
    AppBar
  }
}
</script>

<style lang="less">
.jurisdiction .el-main {
  position: relative;
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 40px 20px 80px;
  box-sizing: border-box;
  overflow-x: hidden;
  .breadcrumb {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
.el-container {
  .jurisdiction-box {
    margin-top: 20px;
    flex: 1;
  }
  .footer {
    position: fixed;
    bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button {
      width: 120px;
      margin-right: 50px;
    }
  }
}
</style>